import * as yup from 'yup'
import { LOCATION_SEARCH_TYPE } from '@/types'
import { STAY_DRIVE, FLIGHT } from './TripTypes'
import { PackagesFormStateType } from '../types'

const LOCATION_REQUIRED = 'Please enter a city or airport'
const INVALID_DATE = 'Please select valid dates'
const DISALLOW_INFANT_INTL_FLIGHT =
  'Sorry, we do not offer lap infant seating on international flights'
const INFANT_CAPACITY_LIMIT = 'Only one infant per adult is allowed'
const CHILD_AGE_REQUIRED =
  "Please enter children's age to get the best price for your group"

const intlStartLocation = (startLocationObject: LOCATION_SEARCH_TYPE | null) =>
  startLocationObject &&
  typeof startLocationObject.countryCode === 'string' &&
  startLocationObject.countryCode.toUpperCase() !== 'US'

const intlEndLocation = (endLocationObject: LOCATION_SEARCH_TYPE | null) =>
  endLocationObject &&
  typeof endLocationObject.countryCode === 'string' &&
  endLocationObject.countryCode.toUpperCase() !== 'US'

export const isInfantFound = (childrenAges: number[]) =>
  Array.isArray(childrenAges) && childrenAges.some(age => Number(age) === 0)

const isStartLocationWithInfant = (
  startLocationObject: LOCATION_SEARCH_TYPE | null,
  childrenAges: number[]
) => intlStartLocation(startLocationObject) && isInfantFound(childrenAges)

export const isEndLocationWithInfant = (
  endLocationObject: LOCATION_SEARCH_TYPE | null,
  childrenAges: number[]
) => {
  return intlEndLocation(endLocationObject) && isInfantFound(childrenAges)
}

const isChildrenAgeDataValid = (childrenAges: number[]) =>
  Array.isArray(childrenAges) && childrenAges.length >= 0
export const numOfValidAges = (childrenAges: number[]) =>
  Array.isArray(childrenAges) ? childrenAges.filter(age => age >= 0) : []

export const isInfantOnInternationalTravel = (
  startLocation: LOCATION_SEARCH_TYPE | null,
  endLocation: LOCATION_SEARCH_TYPE | null,
  childrenAges: number[]
) => {
  if (
    isStartLocationWithInfant(startLocation, childrenAges) ||
    isEndLocationWithInfant(endLocation, childrenAges)
  ) {
    return DISALLOW_INFANT_INTL_FLIGHT
  }
  return ''
}

function handleIsInfantOnInternationalTravel(this: yup.TestContext) {
  const { startLocation, endLocation, childrenAges } = this
    .parent as PackagesFormStateType
  if (
    isStartLocationWithInfant(startLocation, childrenAges) ||
    isEndLocationWithInfant(endLocation, childrenAges)
  ) {
    return false
  }
  return true
}

function handleIsChildrenAgeProvided(this: yup.TestContext) {
  const { childrenCount, childrenAges } = this.parent as PackagesFormStateType
  if (
    childrenCount > 0 &&
    isChildrenAgeDataValid(childrenAges) &&
    childrenCount !== numOfValidAges(childrenAges).length
  ) {
    return false
  }
  return true
}

export const isChildrenAgeProvided = (
  childrenCount: number,
  childrenAges: number[]
) => {
  if (
    childrenCount > 0 &&
    isChildrenAgeDataValid(childrenAges) &&
    childrenCount !== numOfValidAges(childrenAges).length
  ) {
    return CHILD_AGE_REQUIRED
  }
  return ''
}

export const isInfantLimitReachedPackages = (
  adultCount: number,
  childrenAges: number[],
  tripType: string
) => {
  const lapInfants = childrenAges?.filter(age => age === 0) || []
  const infantCount = lapInfants.length
  if (tripType?.includes(FLIGHT) && infantCount > adultCount) {
    return INFANT_CAPACITY_LIMIT
  }
  return ''
}

function handleIsInfantLimitReachedPackage(this: yup.TestContext) {
  const { adultCount, childrenAges, tripType } = this.parent
  if (isInfantLimitReachedPackages(adultCount, childrenAges, tripType)) {
    return false
  }
  return true
}

export const searchFormSchema = yup.object().shape({
  tripType: yup.string(),
  partialStay: yup.bool(),
  startLocation: yup
    .object()
    .nullable()
    .when('tripType', {
      is: STAY_DRIVE,
      then: schema => schema.nullable(),
      otherwise: schema => schema.nullable().required(LOCATION_REQUIRED)
    }),
  endLocation: yup.object().nullable().required(LOCATION_REQUIRED),
  rcLocation: yup
    .object()
    .nullable()
    .when('pickupCarSomewhere', {
      is: true,
      then: schema => schema.nullable().required(LOCATION_REQUIRED),
      otherwise: schema => schema.nullable()
    }),
  startDate: yup.string().required(INVALID_DATE),
  endDate: yup.string().required(INVALID_DATE),
  hotelStartDate: yup.string().when('partialStay', {
    is: true,
    then: schema => schema.required(INVALID_DATE)
  }),
  hotelEndDate: yup.string().when('partialStay', {
    is: true,
    then: schema => schema.required(INVALID_DATE)
  }),
  rcPickupDate: yup.string().when('pickupCarSomewhere', {
    is: true,
    then: schema => schema.nullable().required(INVALID_DATE)
  }),
  rcDropoffDate: yup.string().when('pickupCarSomewhere', {
    is: true,
    then: schema => schema.nullable().required(INVALID_DATE)
  }),
  isInfantOnInternationalTravel: yup
    .string()
    .test(
      'isInfantOnInternationalTravel',
      DISALLOW_INFANT_INTL_FLIGHT,
      handleIsInfantOnInternationalTravel
    ),
  isChildrenAgeProvided: yup
    .string()
    .test(
      'isChildrenAgeProvided',
      CHILD_AGE_REQUIRED,
      handleIsChildrenAgeProvided
    ),
  isInfantLimitReachedPackages: yup
    .string()
    .test(
      'isInfantLimitReachedPackages',
      INFANT_CAPACITY_LIMIT,
      handleIsInfantLimitReachedPackage
    )
})
