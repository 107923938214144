export const STAY = 'STAY'
export const FLY = 'FLY'
export const DRIVE = 'DRIVE'
export const DRIVE_STAY = 'DRIVE:STAY'
export const RENTAL_CAR_PRODUCT_TYPE = 'cars'

export const CALENDAR_POPOVER_SELECTOR =
  '[aria-label="Calendar navigation tooltip"]'

export const partnerInfo = [
  { name: 'ACE', symbol: 'AC' },
  { name: 'Advantage', symbol: 'AD' },
  { name: 'Alamo', symbol: 'AL' },
  { name: 'Avis', symbol: 'AV' },
  { name: 'Budget', symbol: 'BU' },
  { name: 'Driving Force', symbol: 'DF' },
  { name: 'Europcar', symbol: 'EP' },
  { name: 'EASIRENT.com', symbol: 'ES' },
  { name: 'Enterprise', symbol: 'ET' },
  { name: 'American Rent A Car', symbol: 'EX' },
  { name: 'Economy', symbol: 'EY' },
  { name: 'EZ Rent A Car', symbol: 'EZ' },
  { name: 'Firefly', symbol: 'FF' },
  { name: 'Foco', symbol: 'Foco' },
  { name: 'Fox', symbol: 'FX' },
  { name: 'Green Motion', symbol: 'GI' },
  { name: 'Hertz', symbol: 'HZ' },
  { name: 'Localiza', symbol: 'Localiza' },
  { name: 'Movida', symbol: 'Movida' },
  { name: 'Midway', symbol: 'MW' },
  { name: 'Mex Rent A Car', symbol: 'MX' },
  { name: 'National', symbol: 'NA' },
  { name: 'Next Car', symbol: 'NC' },
  { name: 'NU', symbol: 'NU' },
  { name: 'Routes', symbol: 'RO' },
  { name: 'State Van Rental', symbol: 'SR' },
  { name: 'U-Save', symbol: 'SV' },
  { name: 'Sixt', symbol: 'SX' },
  { name: 'Unidas', symbol: 'Unidas' },
  { name: 'AVR', symbol: 'VR' },
  { name: 'Payless', symbol: 'ZA' },
  { name: 'Dollar', symbol: 'ZR' },
  { name: 'Thrifty', symbol: 'ZT' }
]

export const showPartnersDesktop = [
  'BU',
  'HZ',
  'AV',
  'ET',
  'ZR',
  'ZT',
  'AL',
  'FX',
  'SX',
  'NA',
  'AC',
  'ZA',
  'EY',
  'NU',
  'RO',
  'ES',
  'AD',
  'NC',
  'MW',
  'GI',
  'MX',
  'SV',
  'VR',
  'EP',
  'DF',
  'EX',
  'FF',
  'SR'
]

export const showPartnersMobile = [
  'HZ',
  'AV',
  'BU',
  'ET',
  'NA',
  'ZR',
  'AL',
  'EP',
  'SX'
]
