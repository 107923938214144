import { DRIVE } from '@/components/Cars/constants'
import { FLY } from '@/components/Flights/constants'
import { STAY } from '@/components/Hotels/constants'
import { GA4PageNameType, GA4Product, PackageProductType } from './types'

type BuildGA4ObjectForBXSRadioButtonInput = {
  tripType: string
  pageName: GA4PageNameType
}

const isValidTripTypeArray = (
  tripTypesArray: string[]
): tripTypesArray is PackageProductType[] => {
  return (
    tripTypesArray.find(
      tripType => tripType !== STAY && tripType !== FLY && tripType !== DRIVE
    ) === undefined
  )
}

const generateRadioButtonProduct = (tripType: string) => {
  const typesArr = tripType.split(':')
  if (isValidTripTypeArray(typesArr)) {
    const ga4TypeArr = typesArr.map(type => GA4Product[type])
    return ga4TypeArr.join('_')
  }
  return ''
}

const buildGA4ObjectForBXSRadioButton = ({
  tripType,
  pageName
}: BuildGA4ObjectForBXSRadioButtonInput) => ({
  event: 'select_promotion_bundle',
  attributes: {
    type: 'package',
    page_name: pageName,
    product_name: 'bundle',
    radio_button_product: generateRadioButtonProduct(tripType)
  }
})

export default buildGA4ObjectForBXSRadioButton
