import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Box,
  Label,
  Checkbox,
  Text,
  Link,
  FormField,
  Select,
  theme,
  type SelectProps
} from 'pcln-design-system'
import { Calendar } from '@pcln/date-picker'
import StyledHeading from '@/components/StyledHeading'
import CalendarWrapperBox from '@/components/CalenderWrapperBox.styled'
import DateField from '@/components/DateField'
import ShadowEffect from '@/components/ShadowEffect'
import SearchFormButton from '@/components/SearchFormButton'
import TypeAhead from '@/components/TypeAhead'
import {
  formatDate,
  getPackagesDateRange,
  addFormattedDays,
  defaultMaxDate,
  defaultToday,
  getRcMinDate,
  getRcMaxDate,
  getTimeOptions
} from '@/shared-utils/date-helper'
import { PopoverContainer } from '@/components/Popover'
import { swap } from '@/shared-utils/ramda-helper'
import { VP_DISCLAIMER } from '@/components/TravelerSelection/constants'
import analytics from '@/shared-utils/analytics'
import TravelerSelection from '@/components/TravelerSelection/TravelerSelection'
import useTabGroup from '@/hooks/useTabGroup'
import useContentfulSearchFormHeadlines from '@/hooks/useContentfulSearchFormHeadlines'
import useBootstrapData from '@/hooks/useBootstrapData'
import buildGA4ObjectForBXSRadioButton from '@/shared-utils/ga4/bxs-radio-buttons'
import ConditionalRender, {
  Experiment,
  Original
} from '@/components/ConditionalRender'
import useSeti from '@/hooks/useSeti'
import { GA4PageName, GA4PageNameType } from '@/shared-utils/ga4/types'
import { fireInfantErrorEvent } from '@/components/TravelerSelection/ga4'
import { TripTypeInfos, isHotelPlusCar, hasHotel, hasFlight } from './TripTypes'
import {
  fields,
  initialState,
  CALENDAR_POPOVER_SELECTOR,
  getTextString,
  formatPackageSearchHref
} from './utils'
import TripTypeRadios from './TripTypeRadios'
import {
  isInfantOnInternationalTravel,
  isChildrenAgeProvided,
  isInfantLimitReachedPackages,
  searchFormSchema
} from './validation'
import FormContainer from './FormContainer'
import { PackagesFormStateType } from '../types'
import { trackPackageSearchLinkClick } from '../GA4Helpers'

const InlineBlockBox = styled(Box)`
  display: inline-block;
`

const FlexLabel = styled(Label)`
  display: flex;
  align-items: center;
`

const StyledSelect = styled(Select)`
  background: ${theme.colors.white};
  &::-ms-expand {
    display: none;
  }
  cursor: pointer;
`

interface TimeFieldProps extends SelectProps {
  label: string
}

function TimeField({ name, label, ...props }: TimeFieldProps) {
  return (
    <FormField>
      <Label>{label}</Label>
      <StyledSelect {...props} id={name} name={name}>
        {getTimeOptions()}
      </StyledSelect>
    </FormField>
  )
}

type FormProps = {
  onSubmit: (pageName: GA4PageNameType, data: PackagesFormStateType) => void
  isLandingPage?: boolean
}

function Form({ onSubmit, isLandingPage = true }: FormProps) {
  const { registerTabElement, focusNextElement } = useTabGroup()
  const { isMobile = false, prePopData } = useBootstrapData()
  const {
    tripType,
    header,
    startDate: prePopStartDate,
    endDate: prePopEndDate,
    startLocation,
    endLocation,
    adultCount,
    childrenCount,
    infantCount,
    roomCount
  } = prePopData?.packages ?? {}
  const errorFired = useRef(false)
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isRcPickupCalendarOpen, setIsRcPickupCalendarOpen] = useState(false)
  const [isRcDropoffCalendarOpen, setIsRcDropoffCalendarOpen] = useState(false)
  const [isHotelCalendarOpen, setIsHotelCalendarOpen] = useState(false)

  const primaryHeadingText = 'Save up to $625 on your '

  const buttonText = 'Find Your Trip'
  const callToAction =
    'Book a hotel, flight or car with free cancellation for flexibility'
  const prePopInitialState = {
    ...initialState,
    ...(tripType && { tripType }),
    ...(prePopStartDate && { startDate: prePopStartDate }),
    ...(prePopEndDate && { endDate: prePopEndDate }),
    ...(startLocation && { startLocation }),
    ...(endLocation && { endLocation }),
    ...(adultCount && { adultCount }),
    ...(childrenCount && { childrenCount }),
    ...(infantCount && { infantCount }),
    ...(roomCount && { roomCount })
  }

  const searchForm = useContentfulSearchFormHeadlines('searchForm')
  const defaultTripTypes = Object.values(TripTypeInfos)
  const mobileDefaultTripTypes = swap(1, 2, defaultTripTypes)
  const tripTypes = isMobile ? mobileDefaultTripTypes : defaultTripTypes
  const maxDaysHotelToSelect = 30
  const advancedSearchSetiName = isLandingPage
    ? 'PKG_FE_ADVANCED_SEARCH_LANDING'
    : 'PKG_FE_ADVANCED_SEARCH'
  const isUsingAdvancedSearch =
    useSeti(advancedSearchSetiName, false) === 'VARIANT'

  const isDatePickerNewDesign =
    useSeti('HP_NEW_DATEPICKER_DESIGN', false) === 'VARIANT'

  const pageName = isLandingPage
    ? GA4PageName.HOTEL_HOME
    : GA4PageName.PCLN_HOME
  return (
    <FormContainer
      onSubmit={(data: PackagesFormStateType) => {
        analytics.fireGA4Event(
          buildGA4ObjectForBXSRadioButton({
            tripType: data.tripType,
            pageName
          })
        )
        onSubmit(pageName, data)
      }}
      fields={fields}
      initialState={prePopInitialState}
      searchFormSchema={searchFormSchema}
    >
      {({
        setValue,
        errors,
        register,
        watchState: {
          watchTripType,
          watchAdultCount,
          watchRoomCount,
          watchChildrenAges,
          watchChildrenCount,
          watchHotelStartDate,
          watchHotelEndDate,
          watchStartDate,
          watchEndDate,
          watchPartialStay,
          watchStartLocation,
          watchEndLocation,
          watchPickupCarSomewhere,
          watchRcLocation,
          watchRcPickupDate,
          watchRcDropoffDate,
          watchRcPickupTime,
          watchRcDropoffTime
        }
      }) => {
        const finalPrimaryHeadingText =
          getTextString(watchTripType)(primaryHeadingText)
        const contentfulHeadline = isMobile
          ? searchForm?.mobileHeadline
          : searchForm?.headline
        const headline = header
          ? `Save big on bundles to ${header}`
          : contentfulHeadline || finalPrimaryHeadingText

        const carLocationLabel = 'City, Airport or Address'

        const maxDaysToSelect = !hasHotel(watchTripType)
          ? 330
          : maxDaysHotelToSelect

        const childAgeSelectors = {
          optionsText: {
            infant: hasFlight(watchTripType) ? '0-1 infant in lap' : 'Infant',
            oneYear: hasFlight(watchTripType)
              ? '0-1 infant in seat'
              : '1 year old'
          }
        }
        if (
          (isInfantOnInternationalTravel(
            watchStartLocation,
            watchEndLocation,
            watchChildrenAges
          ) ||
            isInfantLimitReachedPackages(
              watchAdultCount,
              watchChildrenAges,
              watchTripType
            )) &&
          !errorFired.current
        ) {
          errorFired.current = true
          fireInfantErrorEvent(pageName, 'bundle')
        }
        return (
          <>
            {isLandingPage && (
              <StyledHeading
                pb={1}
                tag="h1"
                color="text.heading"
                text={headline}
                textStyle={['heading3', null, null, null, 'heading2']}
              />
            )}
            <Box>
              <TripTypeRadios
                tripTypes={tripTypes}
                watchTripType={watchTripType}
                setValue={setValue}
              />
              <Flex flexWrap="wrap" mx={-2}>
                {!isHotelPlusCar(watchTripType) && (
                  <Box width={[1, null, 1 / 2]} px={2} mt={3}>
                    <ShadowEffect enabled>
                      {({
                        disableShadowState
                      }: {
                        disableShadowState: () => void
                      }) => (
                        <TypeAhead
                          searchProduct="packagesOrigin"
                          label="Where from?"
                          placeholder="Where from?"
                          searchKey="startLocation"
                          setValue={setValue}
                          errors={errors}
                          disableShadowState={disableShadowState}
                          defaultSelectedItem={watchStartLocation}
                          ref={registerTabElement}
                          onItemSelect={() => {
                            if (
                              isMobile &&
                              document.activeElement instanceof HTMLElement
                            ) {
                              document.activeElement.blur()
                            }
                          }}
                          focusNextElement={focusNextElement}
                        />
                      )}
                    </ShadowEffect>
                  </Box>
                )}
                <Box
                  width={[1, null, isHotelPlusCar(watchTripType) ? 1 : 1 / 2]}
                  px={2}
                  mt={[isHotelPlusCar(watchTripType) ? 3 : 0, null, 3]}
                >
                  <ShadowEffect enabled>
                    {({
                      disableShadowState
                    }: {
                      disableShadowState: () => void
                    }) => (
                      <TypeAhead
                        searchProduct="hotels"
                        label="Where to?"
                        placeholder="Where to?"
                        searchKey="endLocation"
                        errors={errors}
                        setValue={setValue}
                        disableShadowState={disableShadowState}
                        defaultSelectedItem={watchEndLocation}
                        onItemSelect={item => {
                          // auto-populate RC location if not set yet
                          if (!watchRcLocation) {
                            setValue('rcLocation', item, { shouldDirty: true })
                          }
                          if (
                            isMobile &&
                            document.activeElement instanceof HTMLElement
                          ) {
                            document.activeElement.blur()
                          }
                        }}
                        ref={registerTabElement}
                        focusNextElement={focusNextElement}
                      />
                    )}
                  </ShadowEffect>
                </Box>
              </Flex>
              {watchTripType.includes('FLY') && (
                <InlineBlockBox mt={[0, null, 3]}>
                  <Link
                    href={formatPackageSearchHref({
                      watchTripType,
                      watchStartLocation,
                      watchEndLocation,
                      watchStartDate,
                      watchEndDate,
                      watchRoomCount,
                      watchAdultCount,
                      watchChildrenCount,
                      watchChildrenAges,
                      watchHotelStartDate,
                      watchHotelEndDate,
                      watchPartialStay,
                      isUsingAdvancedSearch
                    })}
                    target="_blank"
                    onClick={() =>
                      trackPackageSearchLinkClick({
                        isLandingPage,
                        isUsingAdvancedSearch
                      })
                    }
                  >
                    <ConditionalRender experimentCode={advancedSearchSetiName}>
                      <Original>
                        <Text fontWeight="bold">I need a one-way flight</Text>
                      </Original>

                      <Experiment>
                        <Text fontSize="14px" fontWeight="bold">
                          Advanced Search (One-Way Flight, Multi-Hotel)
                        </Text>
                      </Experiment>
                    </ConditionalRender>
                  </Link>
                </InlineBlockBox>
              )}
              <Flex flexWrap="wrap" alignItems="flex-start" mx={-2}>
                <Flex width={[1, null, null, null, 2 / 3]} flexWrap="wrap">
                  <CalendarWrapperBox
                    px={2}
                    mt={
                      (isHotelPlusCar(watchTripType) ||
                        isUsingAdvancedSearch) &&
                      isMobile
                        ? 0
                        : 3
                    }
                    width={[1, null, 1 / 2]}
                  >
                    <ShadowEffect enabled>
                      {({
                        disableShadowState
                      }: {
                        disableShadowState: () => void
                      }) => (
                        <PopoverContainer
                          data-calendar
                          open={isCalendarOpen}
                          onDismiss={() => {
                            setIsCalendarOpen(false)
                            disableShadowState()
                          }}
                          domSelectorForVisuallyContainedElement={
                            CALENDAR_POPOVER_SELECTOR
                          }
                        >
                          <DateField
                            name="travelDates"
                            index={1}
                            label={
                              isHotelPlusCar(watchTripType)
                                ? 'Check-in   -   Check-out'
                                : 'Departing - Returning'
                            }
                            ref={registerTabElement}
                            value={getPackagesDateRange({
                              startDate: watchStartDate,
                              endDate: watchEndDate
                            })}
                            onFocus={() => {
                              setIsCalendarOpen(true)
                            }}
                            error={(() => {
                              if (errors.startDate) {
                                return errors.startDate.message
                              }
                              if (errors.endDate) {
                                return errors.endDate.message
                              }
                            })()}
                            aria-expanded={isCalendarOpen ? 'true' : 'false'}
                          />

                          <Calendar
                            use2024DesignSeti={isDatePickerNewDesign}
                            isMobile={isMobile}
                            roundedCorners
                            monthWidthPx={350}
                            startDate={watchStartDate}
                            endDate={watchEndDate}
                            maxDaysToSelect={maxDaysToSelect}
                            onChange={(_, { startDate, endDate }) => {
                              if (startDate && endDate) {
                                setIsCalendarOpen(false)
                                disableShadowState()
                              }
                              setValue('startDate', startDate || '', {
                                shouldDirty: true
                              })
                              setValue('endDate', endDate || '', {
                                shouldDirty: true
                              })
                              setValue('hotelStartDate', startDate || '', {
                                shouldDirty: true
                              })
                              setValue('hotelEndDate', endDate || '', {
                                shouldDirty: true
                              })

                              // auto-populate RC dates if not set yet
                              if (!watchRcPickupDate)
                                setValue('rcPickupDate', startDate, {
                                  shouldDirty: true
                                })
                              if (!watchRcDropoffDate)
                                setValue('rcDropoffDate', endDate, {
                                  shouldDirty: true
                                })
                            }}
                            useDefaultFooter
                            onDismiss={() => {
                              setIsCalendarOpen(false)
                              disableShadowState()
                            }}
                          />
                        </PopoverContainer>
                      )}
                    </ShadowEffect>
                  </CalendarWrapperBox>
                  <Box px={2} mt={[0, null, 3]} width={[1, null, 1 / 2]}>
                    <ShadowEffect enabled alternativeBlurHandling>
                      {({
                        disableShadowState
                      }: {
                        disableShadowState: () => void
                      }) => (
                        <TravelerSelection
                          disclaimer={VP_DISCLAIMER}
                          onDoneButtonClick={disableShadowState}
                          roomCount={watchRoomCount}
                          adultCount={watchAdultCount}
                          childrenAges={watchChildrenAges}
                          childrenCount={watchChildrenCount}
                          showChildAgeSelectors
                          showRoomMenuItem={hasHotel(watchTripType)}
                          showSubLabel={false}
                          setValue={setValue}
                          disableShadowState={disableShadowState}
                          error={
                            isInfantOnInternationalTravel(
                              watchStartLocation,
                              watchEndLocation,
                              watchChildrenAges
                            ) ||
                            isInfantLimitReachedPackages(
                              watchAdultCount,
                              watchChildrenAges,
                              watchTripType
                            ) ||
                            isChildrenAgeProvided(
                              watchChildrenCount,
                              watchChildrenAges
                            )
                          }
                          infantCount={0}
                          showInfantMenuItem={false}
                          childAgeSelectors={childAgeSelectors}
                        />
                      )}
                    </ShadowEffect>
                  </Box>
                  <Box mt={2} width={1}>
                    <Flex ml={1} flexWrap={['wrap', null, 'nowrap']}>
                      {!isHotelPlusCar(watchTripType) &&
                        hasHotel(watchTripType) && (
                          <FlexLabel nowrap fontSize={0} mr={2}>
                            <Checkbox {...register('partialStay')} /> I only
                            need a hotel for part of my stay
                          </FlexLabel>
                        )}
                    </Flex>
                    {watchPartialStay && (
                      <Box width={[1, null, 1 / 2]} mt={2} px={2}>
                        <ShadowEffect enabled>
                          {({
                            disableShadowState
                          }: {
                            disableShadowState: () => void
                          }) => (
                            <PopoverContainer
                              data-hotel-calendar="true"
                              domSelectorForVisuallyContainedElement={
                                CALENDAR_POPOVER_SELECTOR
                              }
                              onDismiss={() => {
                                setIsHotelCalendarOpen(false)
                                disableShadowState()
                              }}
                              open={isHotelCalendarOpen}
                            >
                              <DateField
                                index={2}
                                aria-expanded={
                                  isHotelCalendarOpen ? 'true' : 'false'
                                }
                                error={(() => {
                                  if (errors.hotelStartDate) {
                                    return errors.hotelStartDate.message
                                  }
                                  if (errors.hotelEndDate) {
                                    return errors.hotelEndDate.message
                                  }
                                })()}
                                label="Check-in - Check-out"
                                name="hotelDates"
                                onFocus={() => {
                                  setIsHotelCalendarOpen(true)
                                }}
                                value={getPackagesDateRange({
                                  startDate: watchHotelStartDate ?? null,
                                  endDate: watchHotelEndDate ?? null
                                })}
                              />
                              <Calendar
                                use2024DesignSeti={isDatePickerNewDesign}
                                isMobile={isMobile}
                                startDate={watchHotelStartDate}
                                endDate={watchHotelEndDate}
                                maxDate={
                                  watchEndDate
                                    ? addFormattedDays(watchEndDate, 1)
                                    : defaultMaxDate
                                }
                                minDate={watchStartDate || defaultToday}
                                maxDaysToSelect={maxDaysToSelect}
                                roundedCorners
                                monthWidthPx={350}
                                onChange={(_, { startDate, endDate }) => {
                                  if (startDate && endDate) {
                                    setIsHotelCalendarOpen(false)
                                    disableShadowState()
                                  }
                                  setValue('hotelStartDate', startDate || '', {
                                    shouldDirty: true
                                  })
                                  setValue('hotelEndDate', endDate || '', {
                                    shouldDirty: true
                                  })
                                }}
                                monthsToShow={
                                  watchStartDate && watchEndDate ? 1 : 2
                                }
                                onDismiss={() => {
                                  setIsHotelCalendarOpen(false)
                                  disableShadowState()
                                }}
                                useDefaultFooter
                              />
                            </PopoverContainer>
                          )}
                        </ShadowEffect>
                      </Box>
                    )}
                  </Box>
                </Flex>
                {isHotelPlusCar(watchTripType) && (
                  <Flex flexWrap="wrap" alignItems="flex-start">
                    <Flex mt={2}>
                      <FlexLabel nowrap fontSize={0} ml={1}>
                        <Checkbox {...register('pickupCarSomewhere')} />I want
                        to pick up my car somewhere else
                      </FlexLabel>
                    </Flex>

                    {watchPickupCarSomewhere && (
                      <Flex
                        flexWrap="wrap"
                        alignItems="flex-start"
                        width={[1, null, 1]}
                        px={2}
                        mt={2}
                      >
                        <ShadowEffect enabled>
                          {({
                            disableShadowState
                          }: {
                            disableShadowState: () => void
                          }) => (
                            <TypeAhead
                              searchProduct="cars"
                              label={carLocationLabel}
                              placeholder={carLocationLabel}
                              searchKey="rcLocation"
                              errors={errors}
                              setValue={setValue}
                              disableShadowState={disableShadowState}
                              defaultSelectedItem={watchRcLocation ?? null}
                              ref={registerTabElement}
                              onItemSelect={() => {
                                if (
                                  isMobile &&
                                  document.activeElement instanceof HTMLElement
                                ) {
                                  document.activeElement.blur()
                                }
                              }}
                              focusNextElement={focusNextElement}
                            />
                          )}
                        </ShadowEffect>
                        <Flex mt={2}>
                          <FlexLabel nowrap fontSize={0} ml={-2}>
                            <Checkbox
                              {...register('pickupCarIncludeAirport')}
                            />{' '}
                            Include airport pick-up and drop-off locations
                          </FlexLabel>
                        </Flex>
                        <Flex flexWrap="wrap">
                          <CalendarWrapperBox
                            mt={3}
                            width={[1 / 2, null, 1 / 4]}
                          >
                            <ShadowEffect enabled>
                              {({
                                disableShadowState
                              }: {
                                disableShadowState: () => void
                              }) => (
                                <PopoverContainer
                                  data-calendar
                                  open={isRcPickupCalendarOpen}
                                  onDismiss={() => {
                                    setIsRcPickupCalendarOpen(false)
                                    disableShadowState()
                                  }}
                                  domSelectorForVisuallyContainedElement={
                                    CALENDAR_POPOVER_SELECTOR
                                  }
                                >
                                  <DateField
                                    name="rcPickupDate"
                                    label="Pick-up"
                                    index={3}
                                    ref={registerTabElement}
                                    value={formatDate(
                                      watchRcPickupDate ?? null
                                    )}
                                    onFocus={() => {
                                      setIsRcPickupCalendarOpen(true)
                                    }}
                                    error={(() => {
                                      if (errors.rcPickupDate) {
                                        return errors.rcPickupDate.message
                                      }
                                    })()}
                                    aria-expanded={
                                      isRcPickupCalendarOpen ? 'true' : 'false'
                                    }
                                  />
                                  <Calendar
                                    use2024DesignSeti={isDatePickerNewDesign}
                                    isMobile={isMobile}
                                    roundedCorners
                                    monthWidthPx={350}
                                    monthsToShow={2}
                                    selectTwoDates={false}
                                    showHoveredDates={false}
                                    maxDate={getRcMaxDate(watchRcDropoffDate)}
                                    startDate={watchRcPickupDate}
                                    onChange={(_, { startDate }) => {
                                      if (startDate) {
                                        setIsRcPickupCalendarOpen(false)
                                        disableShadowState()
                                      }
                                      setValue(
                                        'rcPickupDate',
                                        startDate || '',
                                        { shouldDirty: true }
                                      )
                                    }}
                                    useDefaultFooter
                                    onDismiss={() => {
                                      setIsRcPickupCalendarOpen(false)
                                      disableShadowState()
                                    }}
                                  />
                                </PopoverContainer>
                              )}
                            </ShadowEffect>
                          </CalendarWrapperBox>
                          <Box px={2} mt={3} width={[1 / 2, null, 1 / 4]}>
                            <ShadowEffect enabled>
                              <TimeField
                                id="rc-pickup-time"
                                name="rcPickupTime"
                                label="Pick-up Time"
                                style={{ height: isMobile ? '46px' : '56px' }}
                                value={watchRcPickupTime}
                                onChange={(
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                  setValue(
                                    'rcPickupTime',
                                    e.currentTarget.value
                                  )
                                  e.target.blur()
                                }}
                              />
                            </ShadowEffect>
                          </Box>
                          <CalendarWrapperBox
                            mt={3}
                            width={[1 / 2, null, 1 / 4]}
                          >
                            <ShadowEffect enabled>
                              {({
                                disableShadowState
                              }: {
                                disableShadowState: () => void
                              }) => (
                                <PopoverContainer
                                  data-calendar
                                  open={isRcDropoffCalendarOpen}
                                  onDismiss={() => {
                                    setIsRcDropoffCalendarOpen(false)
                                    disableShadowState()
                                  }}
                                  domSelectorForVisuallyContainedElement={
                                    CALENDAR_POPOVER_SELECTOR
                                  }
                                >
                                  <DateField
                                    name="rcDropoffDate"
                                    label="Drop-off"
                                    index={4}
                                    ref={registerTabElement}
                                    value={formatDate(
                                      watchRcDropoffDate ?? null
                                    )}
                                    onFocus={() => {
                                      setIsRcDropoffCalendarOpen(true)
                                    }}
                                    error={(() => {
                                      if (errors.rcDropoffDate) {
                                        return errors.rcDropoffDate.message
                                      }
                                    })()}
                                    aria-expanded={
                                      isRcDropoffCalendarOpen ? 'true' : 'false'
                                    }
                                  />
                                  <Calendar
                                    use2024DesignSeti={isDatePickerNewDesign}
                                    isMobile={isMobile}
                                    monthsToShow={2}
                                    roundedCorners
                                    monthWidthPx={350}
                                    selectTwoDates={false}
                                    showHoveredDates={false}
                                    minDate={getRcMinDate(watchRcPickupDate)}
                                    startDate={watchRcDropoffDate}
                                    onChange={(_, { startDate }) => {
                                      if (startDate) {
                                        setIsRcDropoffCalendarOpen(false)
                                        disableShadowState()
                                      }
                                      setValue(
                                        'rcDropoffDate',
                                        startDate || '',
                                        { shouldDirty: true }
                                      )
                                    }}
                                    useDefaultFooter
                                    onDismiss={() => {
                                      setIsRcDropoffCalendarOpen(false)
                                      disableShadowState()
                                    }}
                                  />
                                </PopoverContainer>
                              )}
                            </ShadowEffect>
                          </CalendarWrapperBox>
                          <Box px={2} mt={3} width={[1 / 2, null, 1 / 4]}>
                            <ShadowEffect enabled>
                              <TimeField
                                id="rc-dropoff-time"
                                name="rcDropoffTime"
                                label="Drop-off Time"
                                style={{ height: isMobile ? '46px' : '56px' }}
                                value={watchRcDropoffTime}
                                onChange={(
                                  e: React.ChangeEvent<HTMLSelectElement>
                                ) => {
                                  setValue(
                                    'rcDropoffTime',
                                    e.currentTarget.value
                                  )
                                  e.target.blur()
                                }}
                              />
                            </ShadowEffect>
                          </Box>
                        </Flex>
                      </Flex>
                    )}
                    <Flex flexWrap="wrap" width={[1, null, null, null, 1]}>
                      <Box width={[1, null, 1 / 2]} px={2} mt={3}>
                        <SearchFormButton buttonText={buttonText} />
                      </Box>
                      <Box width={[1, null, 0.4]} px={2} mt={3}>
                        <Text fontSize="12px" mt={2}>
                          {callToAction}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                )}
                {!isHotelPlusCar(watchTripType) && (
                  <Flex
                    flexDirection="column"
                    width={[1, null, null, null, 1 / 3]}
                    px={2}
                    mt={3}
                    ml="auto"
                  >
                    <SearchFormButton buttonText={buttonText} />
                    <Text fontSize="12px" mt={2}>
                      {callToAction}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          </>
        )
      }}
    </FormContainer>
  )
}

export default Form
